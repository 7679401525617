import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BillPaymentPage from "../../components/body/pages/bill-payments"

const BillPayments = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/bill-payments/"}
      title="Pay Bills Online | Easy to Use App | Kuda"
      description="Kuda makes it easy to pay bills online using our app trusted by over 5 million Nigerians. Pay internet, TV, electricity, transport & education bills with Kuda."
    />
    <BillPaymentPage />
  </Layout>
)

export default BillPayments
