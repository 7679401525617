import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../general/belowFoldCards"
import CTA from "../general/cta"
import FAQList from "../general/faqsList"
import NetworkAntennaIcon from "../../../../assets/img/network-antennas.inline.svg"
import PaymentIcon from "../../../../assets/img/airtime-icon.inline.svg"
import WithdrawIcon from "../../../../assets/img/withdrawIcon.inline.svg"
import ImageTextLeft from "../general/imageTextLeft"
import ImageTextRight from "../general/imageTextRight"
import EwalletBuyIllustration from "../../../../assets/img/kuda-transport-and-education.inline.svg"
import SubscriptionsIllustration from "../../../../assets/img/kuda-tv-and-betting-illustration.inline.svg"
import BillPaymentIllustration from "../../../../assets/img/pay-electricity-internet.inline.svg"
import { scrollToElement } from "../../../utility/utils" 
import EntryIllustration from "../../../../assets/img/pay-a-bill.inline.svg"
import BadgesEntryFold from "../general/badgesEntryFold"

const entryContent={
  title:"Pay your bills quicker on the Kuda mobile and web apps.",
  subtitle:"Add money to your betting wallet, pay internet data and TV subscriptions, and buy electricity meter tokens easily.",
  illustration: <EntryIllustration/>
}
const kudaTopFeatures = [
  {
    icon: <NetworkAntennaIcon />,
    text: "Find all major service providers.",
  },
  {
    icon: <PaymentIcon />,
    text: "Pay your internet subscription in seconds.",
  },
  {
    icon: <WithdrawIcon />,
    text: "Add money to your favourite betting wallet easily.",
  },
]

const meterTokens = {
  title: "Buy meter tokens and data plans easily.",
  subtitle: "Stay connected. Find your electricity distribution company and internet service provider on the Kuda app. ",
  url: "https://help.kuda.com/support/solutions/folders/73000522090",
  name: "Learn More About Bill Payments",
  illustration: <BillPaymentIllustration/>,
}

const questions = [
  {
    heading: "How do I pay bills on the Kuda app?",
    list: (
      <span className="flex flex-column">
        <span>To pay any bill on your Kuda app, follow the steps below: </span>
        <span className="pt-3 career-message-margin-bottom ">
        <ol className="faqs-list-max ordered-list text-left pl-0  leading-md-6 f-md-16">
          <li className="mb-3">Sign in to your Kuda mobile or web app, then tap Pay.</li>
          <li className="mb-3">Tap Pay A Bill.</li>
           <li>Explore bills and service providers, fill in your bill payment details, then complete your transaction.</li>
        </ol>
      </span>
      </span>),
    index: 1
  },

  {
    heading: (<div className="question-title-max">
      How do I get my meter token?
    </div>),
    list: (
      <span className="flex flex-column"><span>We’ll send your token to the email address linked to your Kuda account.
      </span>
      </span>),
    index: 2
  },
  {
    heading: <div className="question-title-max">
   How long does it take for a data plan to be activated?
  </div>,
    list: (
      <span className="flex flex-column"><span>Your data plan doesn’t take more than a few minutes to be activated.</span> </span>),
    index: 3
  },

]
const subscriptions = {
  title: "Pay TV subscriptions and add money to your betting wallet any time.",
  subtitle: " Don’t miss shows and sports action! Your favourite cable TV providers and betting wallets are all on the Kuda app.",
  url:"https://kuda.onelink.me/abUI/344e3dde",
  name: "Download Kuda",
  illustration: <SubscriptionsIllustration/>,
}

const ewallet = {
  payTitle: "Buy JAMB UTME ePINs and top up your Cowry wallet.",
  subtitle: "Skip the stress. JAMB payments and Cowry top-ups are simpler on the Kuda app.",
  url: "/joinKuda/",
  name: "Join Kuda",
  illustration: <EwalletBuyIllustration/>,
}

const BillPaymentPage = () => {
  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
      />
      <BelowFoldCards topFeatures={kudaTopFeatures} />
      <ImageTextRight
        title={meterTokens.title}
        subtitle={meterTokens.subtitle}
        illustration={meterTokens.illustration}
        name={meterTokens.name}
        url={meterTokens.url}
        isExternal={true} 
        
        isImage={true}
      />
      <ImageTextLeft
        title={subscriptions.title}
        subtitle={subscriptions.subtitle}
        illustration={subscriptions.illustration}
        name={subscriptions.name}
        url={subscriptions.url}
        isExternal={true}
      />
      <ImageTextRight
        title={ewallet.payTitle}
        subtitle={ewallet.subtitle}
        illustration={ewallet.illustration}
        name={ewallet.name}
        url={ewallet.url}
        isExternal={true}
      />
      <FAQList title={"Bill Payments FAQs"} questions={questions} />
      <CTA />
    </Fragment>
  )
}

export default BillPaymentPage
